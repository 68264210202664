import React from 'react'
import Link from 'gatsby-link'
import createFragment from 'react-addons-create-fragment'

import locales from '../utils/strings';
import localStyles from './index.module.less';
import Layout from "../components/layout";

import siteInfo from '../../content/info.js';
import {newsEntries, processNewsEntry} from '../utils/newsEntries';

const accessMapImages = {
  'en': require('../images/access_map_ortho.svg'),
  'ja': require('../images/access_map_ortho_ja.svg'),
};

const labMembersImages = {
  jpeg: require('../images/lab_members.jpg'),
  webp: require('../images/lab_members.webp'),
};

const IndexPage = ({ location, pathContext }) => {
  const primaryLang = siteInfo.languages[0]
  const {lang = primaryLang} = pathContext

  if (!locales[lang]) {
    throw new Error(`Unknown language: ${lang}.`);
  }

  const commonStrings = locales[lang].common
  const strings = locales[lang].index

  const ents = newsEntries[lang]
    .slice(0, 5)
    .map(e => processNewsEntry(lang, e))
    .map(({date, headline}, i) => createFragment({
      date: <dt>{date}</dt>,
      headline: <dd dangerouslySetInnerHTML={headline} />,
    }))

  return <Layout location={location}>
    <div className={localStyles.root}>
      <section className={localStyles.hero}>
        <p>{strings.heroText}</p>
      </section>
      <section className={localStyles.newsfeed}>
        <h1>{strings.newsfeed.title}</h1>
        <dl>{ents}</dl>
        <p className={localStyles.newsfeedArchive}>
          <Link to={`/${lang}/news`}>{strings.newsfeed.archiveLinkText}</Link>
        </p>
      </section>
      <section className={localStyles.intro}>
        <h1>{strings.intro.title}</h1>
        <p>{strings.intro.text}</p>
        <p>
          <Link to={`/${lang}/about/embedded-and-real-time-systems`} className={localStyles.linkButton}>{commonStrings.learnMore}</Link>
        </p>
      </section>
      <section className={localStyles.members}>
        <h1>{strings.members.title}</h1>
        <p>
          <picture>
            <source srcSet={labMembersImages.webp} type='image/webp' />
            <img src={labMembersImages.jpeg} alt='' />
          </picture>
        </p>
        <p>
          <Link to={`/${lang}/members`} className={localStyles.linkButton}>{strings.members.linkCaption}</Link>
        </p>
      </section>
      <section className={localStyles.access}>
        <h1>{strings.access.title}</h1>
        <img src={accessMapImages[lang]} alt="" />
        <p>
          <Link to={`/${lang}/about/access`} className={localStyles.linkButton}>{strings.access.linkText}</Link>
        </p>
      </section>
    </div>
  </Layout>
}

export default IndexPage
